import React from "react";

const LogoIcon = props => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>Artboard</title>
    <g fill="none" fillRule="evenodd">
      <path
        d="M34 18v-6H3v16a1 1 0 0 0 1 1h13v3H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h33a2 2 0 0 1 2 2v16h-3zm0-9V4a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v5h31z"
        fill="#fff"
        fillRule="nonzero"
      />
      <circle fill="#fff" fillRule="nonzero" cx="35.5" cy="18" r="1.5" />
      <circle fill="#fff" fillRule="nonzero" cx="17" cy="30.5" r="1.5" />
      <g transform="rotate(-45 35.164 -4.875)">
        <rect
          stroke="#fff"
          strokeWidth="1.617"
          x="1.617"
          y=".808"
          width="12.933"
          height="7.288"
          rx="1.617"
        />
        <path
          d="M9.891 14.976c-.046.902.134 1.938.543 3.107.281.381.47.834.534 1.326l.025.053h-.018c.012.106.018.212.018.32 0 1.593-1.303 2.885-2.91 2.885-1.607 0-2.91-1.292-2.91-2.884 0-.109.006-.215.018-.32h-.018l.025-.06a2.84 2.84 0 0 1 .456-1.208c.395-1.21.55-2.283.465-3.219h-4.18A1.94 1.94 0 0 1 0 13.036v-3.81a1.94 1.94 0 0 1 1.94-1.94h12.286a1.94 1.94 0 0 1 1.94 1.94v3.81a1.94 1.94 0 0 1-1.94 1.94H9.89h.001zm5.466-10.16v-3.2C15.357.725 14.634 0 13.741 0H2.425C1.532 0 .808.724.808 1.617v3.788c.037.002.076.002.115 0 1.248-.036.73-4.963 2.731-2.817 2.002 2.147 2.11.329 3.633.329 1.523 0 1.528 1.223 2.564.953 1.036-.27.707-1.766 2.806-1.765 1.356 0 1.996 1.69 2.7 2.711zM8.083 20.238a.809.809 0 1 0-.001 0h.001z"
          fill="#fff"
          fillRule="nonzero"
        />
      </g>
    </g>
  </svg>
);

export default LogoIcon;
